* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--bg);
}

::-webkit-scrollbar-thumb {
  background: var(--brandcolor3);
  border-radius: 10px;
}

:root {
  --text-light: #211f1f;
  --bg-light: #b49c7c;
  --text-dark: ivory;
  --bg-dark: #452516;
  --inverted-light: #211f1f;
  --inverted-dark: #f2f3f4;
  --brandcolor1: #ece5d5e6;
  --brandcolor2: #416e7c;
  --brandcolor3: #b38f74;
  --nav-cta-brand-light: #211f1f;
  --nav-cta-brand-dark: #febf22;
  --small-padding: 1em;
  --medium-padding: 2em;
  --large-padding: 3em;
  --font-family1: "Alata", sans-serif;
  --font-family2: "Alata", sans-serif;
  --font-familyalt: "Alata", sans-serif;
  --font-clamp: clamp(1.1rem, 2.5vw, 1.1rem);
  --font-clamp-h1: clamp(1.4rem, 5.5vw, 2.3rem);
  --font-clamp-h2: clamp(2rem, 2.5vw, 2.1rem);
  --font-clamp-h3: clamp(.9rem, 2.5vw, 1.1rem);
  --font-clamp-nav: clamp(.7rem, 2.5vw, 1.2rem);
  --font-clamp-footer: clamp(.8rem, 2.5vw, 1rem);
  --text-shadow: 1px 1px 1px var(--brandcolor3);
  --padding-clamp: clamp(.5vw, 1.5vw, 2vw);
  --brandlogo-light: url("https://dww3q6eyff3p0.cloudfront.net/williamhenryblackburn/img/logo-dark.webp");
  --brandlogo-dark: url("https://dww3q6eyff3p0.cloudfront.net/williamhenryblackburn/img/logo-light.webp");
  --homeimage-light: url("https://dww3q6eyff3p0.cloudfront.net/williamhenryblackburn/img/bg-light-theme.webp");
  --homeimage-dark: url("https://dww3q6eyff3p0.cloudfront.net/williamhenryblackburn/img/bg1.webp");
  --presskitimage-light: url("https://dww3q6eyff3p0.cloudfront.net/williamhenryblackburn/img/pk-bg-light.webp");
  --presskitimage-dark: url("https://dww3q6eyff3p0.cloudfront.net/williamhenryblackburn/img/pk-bg-dark.webp");
  --homelogo-light: url("https://dww3q6eyff3p0.cloudfront.net/williamhenryblackburn/img/logo-dark.webp");
  --homelogo-dark: url("https://dww3q6eyff3p0.cloudfront.net/williamhenryblackburn/img/logo-light.webp");
}

@media (prefers-color-scheme: light) {
  :root {
    --text: var(--text-light);
    --bg: var(--bg-light);
    --inverted: var(--inverted-light);
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --text: var(--text-dark);
    --bg: var(--bg-dark);
    --inverted: var(--inverted-dark);
  }
}

[data-theme="dark"] {
  --text: var(--text-dark);
  --bg: var(--bg-dark);
  --inverted: var(--inverted-dark);
  --homeimage: var(--homeimage-dark);
  --homelogo: var(--homelogo-dark);
  --brandlogo: var(--brandlogo-dark);
  --navcta: var(--nav-cta-brand-dark);
  --presskitimage: var(--presskitimage-dark);
}

[data-theme="light"] {
  --text: var(--text-light);
  --bg: var(--bg-light);
  --inverted: var(--inverted-light);
  --homeimage: var(--homeimage-light);
  --homelogo: var(--homelogo-light);
  --brandlogo: var(--brandlogo-light);
  --navcta: var(--nav-cta-brand-light);
  --presskitimage: var(--presskitimage-light);
}

.coda-audio-light-container {
  height: 2.3rem;
  z-index: 999;
  zoom: .8;
  padding: .8rem 0 3rem 2.7rem;
  display: flex;
  position: relative;
}

@media (max-width: 600px) {
  .coda-audio-light-container {
    zoom: .7;
  }
}

.theme-switch {
  height: 25px;
  width: 25px;
  z-index: 25;
  margin-right: .3rem;
  position: relative;
}

.theme-switch input {
  display: none;
}

.switch {
  background-color: var(--brandcolor1);
  cursor: pointer;
  transition: all .4s;
  position: absolute;
  inset: 0;
  transform: rotate(90deg);
}

input:checked + .switch {
  background-color: var(--brandcolor3);
}

input:checked + .switch:before {
  transform: translateX(26px);
}

.toggle {
  background: var(--brandcolor1);
  width: 74px;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  position: relative;
}

.toggle .on, .toggle .off {
  z-index: 10;
  height: 26px;
  width: 29px;
  transition: all .2s;
  position: absolute;
  top: 3px;
  left: 35px;
}

.toggle .off {
  color: var(--text);
  right: 6px;
}

.toggle .on {
  color: var(--text-dark);
  left: 8px;
}

.toggle .slide {
  background: var(--brandcolor2);
  width: 30px;
  height: 25px;
  z-index: 1;
  transition: all .2s;
  position: absolute;
  top: -1px;
  bottom: 0;
  left: 3px;
}

.toggle.active .slide {
  left: 36px;
}

.toggle.active .on {
  color: #5e6f6e;
}

.toggle.active .off {
  color: var(--text-dark);
}

body {
  background-color: var(--bg);
}

.coda-body {
  min-height: 100vh;
  display: flex;
}

.coda-body > .left {
  width: 18rem;
  max-width: 300px;
}

.coda-body > .right {
  flex-grow: 1;
  justify-content: center;
  display: flex;
  position: relative;
}

.coda-body ul li a {
  color: var(--text);
  font-size: .8rem;
  font-family: var(--font-family1);
}

.coda-body ul li a:hover {
  box-shadow: inset 0 0 100px 100px #ffffff1a;
}

.coda-container {
  width: 100%;
}

.section {
  background-color: var(--bg);
  border: 1vw solid var(--brandcolor1);
}

a {
  font-size: var(--font-clamp);
  color: var(--brandcolor3);
  text-transform: uppercase;
  text-decoration: none;
}

.cta-link {
  text-align: center;
  max-width: 13rem;
  border: solid 1px var(--text-light);
  color: var(--text-light);
  flex-direction: column;
  margin-top: 1rem;
  padding: .5rem;
  font-size: 2rem;
  display: flex;
}

h1 {
  font-family: var(--font-family1);
  font-size: var(--font-clamp-h1);
  letter-spacing: .02em;
  color: var(--text-light);
  text-transform: uppercase;
  font-weight: 700;
}

h2 {
  font-family: var(--font-family1);
  color: var(--text-light);
  font-size: var(--font-clamp-h2);
  letter-spacing: .02em;
  margin-bottom: .25em;
  font-weight: 700;
}

h3 {
  font-family: var(--font-family1);
  color: var(--text);
  margin-top: .3rem;
  margin-bottom: 3rem;
  font-size: 2rem;
  font-weight: 600;
}

h4 {
  font-family: var(--font-family1);
  color: var(--text);
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 400;
}

p {
  font-family: var(--font-family2);
  color: var(--text-light);
  font-size: var(--font-clamp);
  font-weight: 400;
}

.center-title {
  padding-left: 1rem;
}

@media (max-width: 840px) {
  .coda-body {
    width: calc(100% + 300px);
    transition: transform .2s ease-out;
    transform: translateX(-300px);
  }

  .coda-body > .left {
    min-width: 300px;
  }
}

.menu {
  height: 95vh;
  position: sticky;
  top: 2rem;
}

.menu input[type="radio"], .menu-toggle {
  z-index: 999;
  display: none;
}

.menu .collapsed-menu {
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  transition: max-height .1s ease-out, opacity .1s ease-in;
}

.menu > li > label, .collapsed-menu li {
  color: var(--text);
  cursor: pointer;
  padding-bottom: .5rem;
  padding-left: 2rem;
  font-style: italic;
  display: block;
}

.collapsed-menu li {
  padding-left: 3rem;
}

.link-expanded {
  font-size: smaller;
}

.menu > li > label:hover, .collapsed-menu li:hover {
  color: #999;
}

.menu input[type="radio"]:checked ~ .collapsed-menu {
  max-height: 300px;
  opacity: 1;
  visibility: visible;
  transition: max-height .35s ease-out, opacity .1s ease-in .2s;
}

.logo-image {
  background-image: var(--brandlogo);
  width: 8rem;
  height: 4.5rem;
  z-index: 999;
  zoom: 1.1;
  background-repeat: no-repeat;
  flex-direction: column;
  margin-top: 2rem;
  margin-left: 1.8rem;
  display: flex;
}

label {
  text-transform: uppercase;
  font-size: .8rem;
}

.nav-copyright {
  color: var(--text);
  text-transform: lowercase;
  padding-left: .6rem;
  font-size: .6rem;
  font-style: italic;
  line-height: 1rem;
  position: absolute;
  bottom: 5px;
}

@media (max-width: 840px) {
  .menu-toggle {
    background-color: var(--brandcolor2);
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    padding: .3rem;
    display: block;
    position: absolute;
    top: 1.2rem;
    left: 1.2rem;
  }

  .menu-toggle div {
    width: 35px;
    height: 5px;
    margin: 6px 0;
  }
}

.portfolio {
  height: 100vh;
  grid-gap: .8rem;
  flex-wrap: wrap;
  display: grid;
}

.portfolio-item-container {
  max-width: 100vw;
  font-family: var(--font-family1);
}

.portfolio-item {
  flex: 1 0 30rem;
  overflow: hidden;
}

.text-heading-image {
  background-color: var(--bg);
  width: 30rem;
  height: 10rem;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  display: flex;
  position: absolute;
  bottom: 0;
}

.portfolio-description {
  font-family: var(--font-family1);
  color: var(--brandcolor1);
  text-transform: uppercase;
  text-align: end;
  padding: 5.5rem 0 1.5rem 1.5rem;
  line-height: 1.2;
}

.portfolio-description2 {
  font-family: var(--font-family1);
  color: var(--brandcolor1);
  text-transform: uppercase;
  text-align: start;
  padding: 1rem;
  line-height: 1.2;
}

.portfolio-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  transition: transform .4s ease-out;
  display: flex;
}

.portfolio-image2 {
  width: 100%;
  transition: transform .4s ease-out;
}

.portfolio-image:hover {
  transform: scale(1.1);
}

@supports (display: grid) {
  .portfolio {
    display: grid;
  }

  .portfolio, .portfolio-item, .portfolio-item-text {
    margin: 0;
  }
}

@media (min-width: 320px) {
  .portfolio {
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  }

  .portfolio-description, .portfolio-description2 {
    font-size: 1rem;
  }

  .portfolio-item, .portfolio-item-text {
    height: 12rem;
  }
}

@media (min-width: 480px) {
  .portfolio {
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  }

  .portfolio-item, .portfolio-item-text {
    height: 12rem;
  }
}

@media (min-width: 768px) {
  .portfolio {
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  }

  .portfolio-item, .portfolio-item-text {
    height: 12rem;
  }
}

@media (min-width: 992px) {
  .portfolio {
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  }

  .portfolio-item, .portfolio-item-text {
    height: 12rem;
  }
}

@media (min-width: 1200px) {
  .portfolio {
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  }

  .portfolio-item, .portfolio-item-text {
    height: 13rem;
  }

  .portfolio-description, .portfolio-description2 {
    font-size: 1rem;
  }
}

@media (min-width: 1400px) {
  .portfolio {
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  }

  .portfolio-item, .portfolio-item-text {
    height: 19rem;
  }

  .portfolio-description, .portfolio-description2 {
    font-size: 1.5rem;
  }
}

.project-full-width {
  max-width: 100vw;
  min-height: 100vh;
  grid-column-gap: .4rem;
  background-image: url("whb-main-screen-2.df695733.webp");
  background-position: 42%;
  background-size: cover;
  grid-template-rows: repeat(auto-fit, 1fr 1fr);
  grid-template-columns: repeat(auto-fit, 1fr 1fr);
  display: grid;
}

.home-header {
  max-width: 50rem;
  background-color: var(--brandcolor1);
  flex-direction: column;
  grid-area: 105 / 1 / span 5 / span 12;
  justify-content: center;
  margin-bottom: 1.3rem;
  margin-left: 1.3rem;
  padding: 1rem;
  display: flex;
}

.home-span {
  font-family: var(--font-family2);
  color: var(--text-light);
  padding-top: 1rem;
  font-size: 1.1rem;
  display: flex;
}

.dob p {
  padding-top: .5rem;
  font-weight: 700;
}

@media screen and (max-width: 990px) {
  .project-full-width {
    grid-column-gap: .1rem;
  }

  .home-header {
    margin-left: 0;
    padding: 1rem;
  }
}

.project-about {
  max-width: 100vw;
  min-height: 100vh;
  grid-column-gap: .4rem;
  grid-template-rows: repeat(auto-fit, 1fr 1fr);
  grid-template-columns: repeat(auto-fit, 1fr 1fr);
  display: grid;
}

.img0 {
  background-image: url("https://dww3q6eyff3p0.cloudfront.net/williamhenryblackburn/img/whb-bio-5.webp");
  background-position: 80% 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.img1 {
  background-image: url("https://dww3q6eyff3p0.cloudfront.net/williamhenryblackburn/img/whb-bio-6.webp");
  background-position: 80% 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.img2 {
  background-image: url("https://dww3q6eyff3p0.cloudfront.net/williamhenryblackburn/img/whb-bio-3.webp");
  background-position: 80% 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.img3 {
  background-image: url("https://dww3q6eyff3p0.cloudfront.net/williamhenryblackburn/img/whb-bio-2.webp");
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.img4 {
  background-image: url("whb-bio-7.f14c10a5.webp");
  background-position: 80% 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.img5 {
  background-image: url("whb-photography.d1869ee5.webp");
  background-position: 80% 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.biography {
  max-width: 50rem;
  background-color: var(--brandcolor1);
  flex-direction: column;
  grid-area: 105 / 1 / span 5 / span 12;
  justify-content: center;
  margin-bottom: 1.3rem;
  margin-left: 1.3rem;
  padding: 1rem;
  display: flex;
}

.biography-a {
  max-width: 50rem;
  background-color: var(--brandcolor1);
  flex-direction: column;
  grid-area: 105 / 1 / span 5 / span 12;
  justify-content: center;
  margin-bottom: 1.3rem;
  margin-left: 1.3rem;
  padding: 2rem;
  display: flex;
}

.biography-b {
  max-width: 50rem;
  background-color: var(--brandcolor1);
  flex-direction: column;
  grid-column: 1 / span 12;
  justify-content: center;
  margin-left: 1.3rem;
  padding: 2rem;
  display: flex;
}

.biography-c {
  max-width: 50rem;
  background-color: var(--brandcolor1);
  flex-direction: column;
  grid-area: 105 / 80 / span 5 / span 12;
  justify-content: center;
  margin-bottom: 1.3rem;
  margin-left: 1.3rem;
  padding: 1rem;
  display: flex;
}

.bio-links {
  justify-content: space-between;
  gap: 1rem;
  display: flex;
}

.about-info {
  max-width: 100%;
  padding-block: .8rem;
  line-height: 120%;
}

.project-wrapper {
  width: 100%;
  height: auto;
  grid-template-columns: 1fr 1fr;
  gap: 4em;
  margin-bottom: 5em;
  display: grid;
  overflow: hidden;
}

.project-thumbnail {
  object-fit: cover;
  width: 100%;
  height: 97vh;
  transition: transform .4s ease-out;
}

.project-thumbnail-auto {
  width: 100%;
  height: 100%;
  transition: transform .4s ease-out;
}

.project-thumbnail:hover, .project-thumbnail-auto:hover {
  transform: scale(1.01);
}

.project-name, .project-name-left {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.project-title {
  color: var(--text);
  font-size: 1.4rem;
  font-weight: bold;
}

.project-description {
  max-width: 40ch;
  padding-block: 2em;
  line-height: 145%;
}

@media screen and (max-width: 990px) {
  .project-about {
    grid-column-gap: .1rem;
  }

  .biography, .biography-a, .biography-b, .biography-c {
    margin-left: 0;
    padding: 5rem 1rem 1rem;
  }
}

.filter-grid-gallery {
  background-color: var(--brandcolor1);
  height: 100vh;
  overflow: auto;
}

.gallery {
  position: relative;
}

.photo {
  max-width: 100%;
  margin: 0 auto;
  transition: all 1.8s ease-in-out;
}

.photo:hover {
  transition: all 1.8s ease-in-out;
  transform: scale(1.01);
}

.lightbox {
  z-index: 999;
  width: 100%;
  height: auto;
  background: #2a2a2ad9;
  padding: 2rem;
  transition: all .4s ease-in;
  position: absolute;
  top: 0;
  left: 0;
}

.lightbox img {
  width: 60vw;
  margin: 0 auto;
  transition: all .4s ease-in;
  display: flex;
  position: relative;
  box-shadow: 1px 1px 10px #2a2a2ad9;
}

@media (max-width: 620px) {
  .filter-grid-gallery {
    padding: 4rem .9rem .9rem;
  }
}

@media (min-width: 460px) {
  .gallery {
    grid-gap: 10px;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    padding: 10px;
    display: grid;
  }
}

@media (min-width: 1000px) {
  .gallery {
    grid-template-columns: repeat(4, minmax(200px, 1fr));
  }
}

.soc {
  width: 12rem;
  zoom: .7;
  max-width: 100%;
  padding-left: 3rem;
  list-style: none;
  display: block;
}

.soc li {
  padding-right: .3rem;
  display: inline-block;
}

.soc a, .soc svg {
  display: block;
}

.icon-28:hover, .icon-4:hover, .icon-3:hover, .icon-2:hover, .icon-1:hover {
  fill: #0e1a25;
  transition: background-color .5s, transform 10.5s ease-out;
}

.icon-1 {
  fill: var(--text);
}

.icon-1:hover {
  background: var(--text);
}

.icon-4 {
  fill: var(--text);
}

.icon-4:hover {
  background: var(--text);
}

.icon-28 {
  fill: var(--text);
}

.icon-28:hover {
  background: var(--text);
}

.contact-form {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  place-items: center;
  display: grid;
}

.contact-label {
  font-family: var(--font-family2);
  font-size: var(--font-clamp);
  color: var(--text-light);
}

.contact-input {
  background-color: var(--brandcolor1);
}

.contact-send {
  font-size: var(--font-clamp);
  background-color: var(--brandcolor1);
  padding: .5rem;
}

.img-contact {
  background-image: url("whb-contact.edbf4492.webp");
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.hover-a {
  --b: .1em;
  --c: #aa6e20;
  color: #0000;
  padding-block: var(--b);
  background: linear-gradient(var(--c) 50%, #000 0) 0% calc(100% - var(--_p, 0%)) / 100% 200%, linear-gradient(var(--c) 0 0) 0% var(--_p, 0%) / var(--_p, 0%) var(--b) no-repeat;
  -webkit-background-clip: text, padding-box;
  transition: .3s var(--_s, 0s) linear, background-size .3s calc(.3s - var(--_s, 0s));
  -webkit-background-clip: text, padding-box;
  background-clip: text, padding-box;
}

.hover-a:hover {
  --_p: 100%;
  --_s: .3s;
}

/*# sourceMappingURL=index.9d336128.css.map */
